import React from 'react'
import { categories } from '../data';
import CategoriesItems from './CategoriesItems';
import './Categorie.css'

const Categorie = () => {
    return ( 
        <div className="category">
        
   
            <CategoriesItems  />
     
            
            </div>)
  
};

export default Categorie


