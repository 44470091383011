import React from 'react'
import Announcement from './Announcement'
import Navba from './Navba'

const ProductSIngle = () => {
    return (
        <div>
      <Announcement/>
      <Navba/>
      <div>ProductSIngle</div>
      </div>
  )
}

export default ProductSIngle