import React from 'react'
import Announcement from '../components/Announcement'
import Navba from '../components/Navba'

const Cart = () => {
    return (
        <div>
            <Announcement />
            <Navba/>
            <div>Cart</div>
            </div>
  )
}

export default Cart